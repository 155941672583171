import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const tracking = createSlice({
    name: 'tracking',
    initialState,
    reducers: {
        maybeSetTrackingEmail: (state, action) => { 
            const email = action.payload;

            // eslint-disable-next-line
            if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
                state.email = email;
            }

            return state;
        },
        triggerEvent: state => state
    }
});

export const { maybeSetTrackingEmail, triggerEvent } = tracking.actions;
export default tracking.reducer;
