import { useContentItem } from 'hooks';
import React, { useMemo } from 'react';

const TrackingPixels = ({ placement }) => {
    const { data: item } = useContentItem();

    const pixels = useMemo(() => (item?.details?.tracking_pixels || []).filter(p => p.placement === placement), [item, placement]);

    return pixels.map(p => <img key={`pixel-${p._id}`} alt="" src={p.pixel} width="1" height="1" style={{opacity: 0}} />);
};

export default TrackingPixels;
