import React, { useMemo } from 'react';
import { useGetPurchasesQuery } from 'store/features/user-api';
import { Loader, BoxedItem, CollaspableSection, Button, Flex } from 'ui';
import { splitCollectionIntoCategories } from 'utils/splitCollectionIntoCategories';
import { ucFirst } from 'utils/ucFirst';
import DashboardFrame from '../DashboardFrame';
import CancelSubscrtionBtn from './CancelSubscriptionBtn';
import BillingInfo from './BillingInfo';
import TransactionsPanel from '../TransactionsPanel';
import renewsOrExpiredLine from './renewsOrExpiredLine';
import Promo from './Promo';

const SubscriptionsScreen = () => {
    const { data: transactions, isFetching } = useGetPurchasesQuery();

    const categories = useMemo(() => {
        return splitCollectionIntoCategories(transactions?.subscriptions || [], sub => {
            if (sub?.state === 'future') return 'future';
            return sub?.state === 'active' || sub?.state === 'canceled' ? 'active' : 'expired';
        }, ['active', 'future', 'expired']);
    }, [transactions]);

    if (isFetching) {
        return <DashboardFrame><Loader small>Loading subscriptions</Loader></DashboardFrame>;
    }

    return (
        <DashboardFrame breadcrumbs={[['Home', '/'], ['Subscriptions', null]]}>
            <h2>Subscriptions</h2>
            {!(categories || []).length && <p>You don't have any subscriptions yet</p>} 

            {categories.map((cat, catI) => {
                let Wrap = React.Fragment;
                let wrapProps = {};
                
                if (categories.length > 1) {
                    Wrap = CollaspableSection;
                    wrapProps = { heading: cat.heading, initialState: catI === 0 };
                }
                
                return (
                    <Wrap key={`cat-${catI}`} {...wrapProps}>
                        {cat.items.map((sub, i) => {
                            const isActive = sub.state === 'active' || sub.state === 'canceled';
                            const site_access = sub?.plan?.site_access || [];
                            const first_site = site_access.length ? site_access[0] : {};

                            return (
                                <BoxedItem
                                    key={`${cat.key}-${i}`}
                                    heading={sub.plan?.name || 'Deleted plan'}
                                    category={ucFirst(sub.state)}
                                    RightChildren={isActive && <Flex flexDirection="column" alignItems="center"><Button as="a" size="small" href={first_site.start_reading} black style={{ marginBottom: '8px'}}>View</Button><CancelSubscrtionBtn sub={sub} /></Flex>}
                                >
                                    <h4 style={{ margin: 0 }}>{sub.owner === 'self' ? renewsOrExpiredLine(sub) : `Managed by ${sub.owner}`}</h4>
                                    {sub.state === 'active' && <Promo subscription={sub} />}
                                </BoxedItem>
                            );
                        })}
                    </Wrap>
                );
            })}

            <BillingInfo />
            <TransactionsPanel />
        </DashboardFrame>
    )
};

export default SubscriptionsScreen;
