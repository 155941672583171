import { CURRENCY_SYMBOLS } from './constants';

export const formatNumber = number => {
    number = `${number}`;

    const number_parts = number.split('.');
    if (number_parts[0].length > 3) {
        number_parts[0] = number_parts[0].split('').reverse().reduce((a, d, i) => {
            if (i % 3 === 0 && i > 0) {
                a.push(',');
            }

            a.push(d);

            return a;
        }, []).reverse().join('');

        number = number_parts.join('.');
    }

    return number;
};

export const formatCurrency = (number, currency = 'USD', makeNegative) => {
    number = Math.floor(number) === number ? number : number.toFixed(2);
    number = formatNumber(number);
    
    const currencySymbol = CURRENCY_SYMBOLS[currency] || '$';

    return `${currency} ${makeNegative ? '-' : ''}${currencySymbol}${number}`;
};
