import moment from 'moment';
import React, { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useCancelSubscriptionMutation } from 'store/features/user-api';
import { LinkButton } from 'ui';

const CancelSubscrtionBtn = ({ sub }) => {
    const [cancelSubscription, { isLoading, error }] = useCancelSubscriptionMutation();

    useEffect(() => {
        if (error) {
            toast.error('Error canceling subscription. Contact Support to cancel.');
        }
    }, [error]);

    const handleClick = useCallback(() => {
        if (window.confirm(`Are you sure you want to cancel this subscription? You will continue to have access until ${moment(sub.current_period_ends_at).format('MM/DD/Y')}.`)) {
            cancelSubscription(sub.id);
        }
    }, [cancelSubscription, sub]);

    if (sub.state !== 'active' || sub.owner !== "self") {
        return null;
    }

    if (isLoading) {
        return <em style={{ fontSize: '15px' }}>Canceling</em>;
    }
    

    return <LinkButton onClick={handleClick}>Cancel</LinkButton>;
};

export default CancelSubscrtionBtn;
