import moment from 'moment';
import React, { useMemo } from 'react';
import { useGetPurchasesQuery } from 'store/features/user-api';
import { Loader, BoxedItem, CollaspableSection, Stats, Stat } from 'ui';
import { formatCurrency } from 'utils/formatNumber';
import { splitCollectionIntoCategories } from 'utils/splitCollectionIntoCategories';
import DashboardFrame from './DashboardFrame';

const GroupTicketsScreen = () => {
    const { data: transactions, isFetching } = useGetPurchasesQuery();

    const categories = useMemo(() => {
        return splitCollectionIntoCategories(transactions?.event_group_registrations || [], reg => {
            const event = reg.webinar;

            if (!event) {
                return 'past';
            }

            return moment(event.event_date).isBefore(moment()) ? 'past' : 'upcoming';
        }, [
            {
                heading: 'Upcoming Events',
                key: 'upcoming'
            },
            {
                heading: 'Past Events',
                key: 'past'
            }
        ]);
    }, [transactions]);

    
    if (isFetching) {
        return <DashboardFrame><Loader small>Loading group tickets</Loader></DashboardFrame>;
    }

    return (
        <DashboardFrame breadcrumbs={[['Home', '/'], ['Group Tickets', null]]}>
            <h2>Group tickets</h2>
            {!(categories || []).length && <p>You haven't purchased any group tickets yet</p>} 

            {categories.map((cat, catI) => {
                if (!cat.items || !cat.items.length) return null;
                let Wrap = React.Fragment;
                let wrapProps = {};
                
                if (categories.length > 1) {
                    Wrap = CollaspableSection;
                    wrapProps = { heading: cat.heading, initialState: catI === 0 };
                }

                return (
                    <Wrap key={`cat-${catI}`} {...wrapProps}>
                        {cat.items.map((purchase, i) => {
                            const event = purchase?.webinar || {};
                            const link = `${process.env.REACT_APP_DOMAIN}/event/${event.event_code}/group/${purchase.group_code}`;

                            return (
                                <BoxedItem
                                    key={`${cat.key}-${i}`}
                                    heading={event.name || 'Missing event'}
                                    category={moment(event.event_date).format('MMMM Do, YYYY')}
                                >
                                    <Stats>
                                        <Stat label="Purchase Date">
                                            {moment(purchase.purchase_date).format('MM/DD/Y h:mm a')}
                                        </Stat>
                                        <Stat label="Payment amount">
                                            {formatCurrency(purchase.payment_amount, purchase.currency)}
                                        </Stat>
                                        <Stat label="Quantity">
                                            {purchase.quantity_purchased}
                                        </Stat>
                                        <Stat label="Registrations Used">
                                            {(purchase.registrations || []).length}
                                        </Stat>
                                        <Stat label="Registration Link">
                                            <a href={link}>{link}</a>
                                        </Stat>
                                    </Stats>
                                    
                                </BoxedItem>
                            );
                        })}
                    </Wrap>
                );
            })}
        </DashboardFrame>
    )
};

export default GroupTicketsScreen;
