import React from 'react'; 
import QRCode from 'qrcode.react';
import styled from 'styled-components';
import TicketHeader from './ticket-header.png';
import AddToWalletBtns from 'components/EventRegistration/AddToWalletBtns';

const Wrapper = styled.div`
    background: #eee;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow-y: auto;
`;
const Container = styled.div`
    width: 100%;    
    max-width: 600px;
    margin: 0 auto;
    background: #1f1f1f;
`;
const HeroImg = styled.img`
    width: 100%;
`;
const Details = styled.div`
    padding: 20px 15px 40px 15px;
    color: #fff;
    text-align: center;
`;
const EventName = styled.h1`
    margin: 0 0 20px 0;
`;
const AttendeeName = styled.p`
    font-size: 1.8em;
    font-weight: bold;
    margin: 20px 0 0 0;
`;
const AttendeeCompany = styled.p`
    font-size: 1.2em;
    margin: 0 0 20px 0;
`;
const QRWrap = styled.div`
    padding: 15px;
    border-radius: 20px;
    background: #fff;
    width: 230px;
    margin: 0 auto;
`;
const FinePrint = styled.p`
    font-size: 0.7em;
`;

const Ticket = ({ registration }) => (
    <Wrapper>
        <Container>
            <HeroImg src={TicketHeader} alt="Skift Live" />
            <Details>
                <EventName>{registration.webinar.name}</EventName>
                <QRWrap><QRCode size={200} value={registration.id} /></QRWrap>
                <AttendeeName>{registration.user?.first_name} {registration.user?.last_name}</AttendeeName>
                <AttendeeCompany>{registration.user?.company}</AttendeeCompany>
                <AddToWalletBtns token={registration.token} />
                <FinePrint>
                    {registration.user.email}<br/>
                    {registration.id}
                </FinePrint>
            </Details>
        </Container>
    </Wrapper>
);

export default Ticket;
