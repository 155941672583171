import { useSelector } from 'react-redux';

import { useGetItemDetailsQuery } from 'store/features/content-api-skapi';
import { useLoggedInUser } from './useLoggedInUser';

export const useContentItem = () => {
    const itemId = useSelector(state => state.transaction.itemId);
    const transactionType = useSelector(state => state.transaction.type);
    const group = useSelector(state => state.transaction.groupCode);
    const { data: user, isFetching: isFetchingUser } = useLoggedInUser();
    const uid = user?.id;

    const hookResult = useGetItemDetailsQuery({ itemId, transactionType, group, uid }, { skip: !itemId || !transactionType || isFetchingUser });

    if (!transactionType || isFetchingUser) {
        return { isLoading: true, isFetching: true };
    }

    return hookResult;
};
