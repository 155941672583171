import { useSelector } from 'react-redux';

import { useCalculateTotalQuery } from 'store/features/content-api-skapi';
import { useContentItem } from './useContentItem';
import { TRANSACTION_TYPES } from 'utils/constants';
import { useAuth0 } from 'components/auth0/Auth0Context';

const hasValidUSAddress = ({ region, country, zip } = {}) => {
    if (country !== 'United States' || !region || !zip) {
        return false;
    }

    if (zip.length !== 5 && zip.length !== 9) {
        return false;
    }

    return true;
};

export const useTransactionTotal = () => {
    const { isAuthenticated, isLoading: auth0isLoading } = useAuth0();
    const { data: item } = useContentItem();
    const { id: itemId, payment_model } = item?.details || {};

    const transactionType = useSelector(state => state.transaction.type);
    const currency = useSelector(state => state.transaction.currency);
    const promo = useSelector(state => state.transaction.promo);
    const groupCode = useSelector(state => state.transaction.groupCode);
    let  { billing_country: country, billing_region: region, billing_zip: zip } = useSelector(state => state.transaction.billingAddress);
    
    const amount = useSelector(state => state.transaction.amount);
    let quantity = useSelector(state => state.transaction.quantity);
    const payWhatYouWant = transactionType === TRANSACTION_TYPES.EVENT && payment_model === 'flex';
    const useAmount = (payWhatYouWant || [TRANSACTION_TYPES.EVENT_GROUP].includes(transactionType)) && amount;

    const skipForTransationType = [TRANSACTION_TYPES.EDU_CREDIT, TRANSACTION_TYPES.ENTERPRISE_COMPANY, TRANSACTION_TYPES.EVENT_GROUP].includes(transactionType);

    if (transactionType === TRANSACTION_TYPES.MULTI_USER_SUBSCRIPTION && quantity < 2) {
        quantity = 2;
    }

    if (!hasValidUSAddress({ region, country, zip })) {
        region = country = zip = null;
    }

    const totalQuery = useCalculateTotalQuery({ 
        transactionType, 
        itemId, 
        currency, 
        promo, 
        groupCode,
        country, 
        region,
        zip,
        isAuthenticated,
        quantity
    }, { skip: !item || !currency || auth0isLoading || useAmount || skipForTransationType});

    if (useAmount) {
        return {
            data: {
                payWhatYouWant,
                total: amount,
                require_payment: amount > 0
            }
        };
    }

    if ((totalQuery.isUninitialized || !totalQuery.data) && !totalQuery.error) {
        return {
            isFetching: true,
            isUninitialized: true
        };
    }

    return totalQuery;
};
