import React from 'react';
import { useGetRegistrationsWithActiveCheckInQuery } from 'store/features/user-api';
import { Message } from 'ui';

const ViewTicketBtn = () => {
    const { data } = useGetRegistrationsWithActiveCheckInQuery();

    if (data && data.length) {
        return data.map(reg => {
            if (!reg.webinar) {
                return null;
            }
            return (
                <Message key={`view-ticket-${reg._id}`} heading={`Your ticket to ${reg.webinar.name}`}>
                    <a href={`/event/${reg.webinar.event_code}/ticket`} target="_blank" rel="noopener noreferrer">View your ticket</a>
                </Message>
            )
        });
    }

    return null;
};

export default ViewTicketBtn;
