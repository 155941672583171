import React, { useMemo } from 'react';
import moment from 'moment';
import { useGetPurchasesQuery } from 'store/features/user-api';
import { splitCollectionIntoCategories } from 'utils/splitCollectionIntoCategories';
import SummaryPane from '../SummaryPane';

const EventRegistrationSummary = () => {
    const { data: transactions } = useGetPurchasesQuery();
    const registrations = transactions?.event_registrations;

    const text = useMemo(() => {
        let text = '';

        const { upcoming = [], past = []} = splitCollectionIntoCategories(registrations || [], reg => {
            const event = reg.webinar;

            if (!event) {
                return false;
            }

            return moment(event.event_date).isBefore(moment()) ? 'past' : 'upcoming';
        });
        
        if (upcoming.length) {
            text += `You are registered for ${upcoming.length} upcoming event${upcoming.length > 1 ? 's' : ''}`;
        }

        if (past.length) {
            if (upcoming.length) {
                text += ' and you ';
            } else {
                text += 'You ';
            }

            text += `have attended ${past.length} event${past.length > 1 ? 's' : ''}`;
        }

        text += '.';

        return text;
    }, [registrations]);


    if (!registrations.length) {
        return;
    }

    return (
        <SummaryPane heading="Event Registrations" to="/event-registrations">
            <p>{text}</p>
        </SummaryPane>
    );
};

export default EventRegistrationSummary;
