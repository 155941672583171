import moment from 'moment';
import React from 'react';
import { useGetPurchasesQuery } from 'store/features/user-api';
import { Loader, BoxedItem, Stats, Stat, Button } from 'ui';
import DashboardFrame from './DashboardFrame';

const EduCreditsScreen = () => {
    const { data: transactions, isFetching } = useGetPurchasesQuery();

    if (isFetching) {
        return <DashboardFrame><Loader small>Loading edu credits</Loader></DashboardFrame>;
    }

    return (
        <DashboardFrame breadcrumbs={[['Home', '/'], ['Edu Credits', null]]}>
            <h2>Edu Credits</h2>
            {!(transactions?.edu_credits || []).length && <p>You haven't redeemed any edu credits yet</p>} 
            {(transactions?.edu_credits || []).map(c => {
                return (
                    <BoxedItem
                        key={c._id}
                        heading={c.item?.item_name}
                        category={(c.item?.credit_type || '').toUpperCase()}
                        RightChildren={c.item?.permalink && <Button as="a" size="small" href={c.item?.permalink} black>Read&nbsp;Now</Button>}
                    >
                        <Stats>
                            <Stat label="Credits">{c.item?.credits} {(c.item?.credit_type || '').toUpperCase()}</Stat>
                            <Stat label="Date Redeemed">{moment(c.date_redeemed).format('MM/DD/Y h:mm a')}</Stat>
                        </Stats>
                    </BoxedItem>
                );
            })}
        </DashboardFrame>
    )
};

export default EduCreditsScreen;
